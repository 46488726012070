import 'react-lazy-load-image-component/src/effects/blur.css'

import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Props as ImageProps } from 'app/components/Common/Image'
import { Ribbon } from 'app/components/Common/Ribbon'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadImage } from 'react-lazy-load-image-component'

import { Props as ServiceProps, Service } from './Service'

export interface Props {
  cta?: ButtonProps
  description?: string
  image?: ImageProps
  services: ServiceProps[]
  title?: string
  variant?: Variant
}

export const GeniusServices = memo(function GeniusServices({
  cta,
  description,
  image,
  services,
  title,
  variant = 'default',
}: Props) {
  if (!services) {
    return null
  }

  return (
    <Container>
      {image ? (
        <LazyLoadImage className="genius-bg" effect="blur" {...image} />
      ) : null}
      <Wrapper row wrap>
        <LeftSide>
          <FadeInUp>
            <Ribbon />
          </FadeInUp>
          {title ? (
            <FadeInUp>
              <Title>{title}</Title>
            </FadeInUp>
          ) : null}
          {description ? (
            <FadeInUp>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
            </FadeInUp>
          ) : null}
          {cta ? (
            <FadeInUp>
              <CTA {...cta} variant={variant === 'default' ? 'W' : 'M'} />
            </FadeInUp>
          ) : null}
        </LeftSide>
        <RightSide row wrap stretch>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </RightSide>
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin-bottom: 15.25rem;
  padding: 5.4375rem 4.167vw 0 5.833vw;
  position: relative;
  &:before {
    content: '';
    width: 100%;
    height: 100%;
    background: ${({ theme }) => theme.colors.variants.neutralDark1};
    opacity: 0.5;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .genius-bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 5rem;
    padding: 4.375rem 6.039vw 0;
    text-align: center;
    &:before,
    .genius-bg {
      height: calc(100% - 5.5rem);
    }
  }
`

const Wrapper = styled(FlexBox)`
  position: relative;
  z-index: 2;
`

const LeftSide = styled.div`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding-right: 9.792vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding-right: 0;
  }
`

const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  font-weight: 600;
  line-height: 4rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    font-size: 3.125rem;
    line-height: 2.875rem;
  }
`

const Description = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.75rem;
  margin-top: 1.125rem;

  @media (max-width: 1023px) {
    margin-top: 1.25rem;
  }
`

const CTA = styled(Button)`
  margin-top: 3.125rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`

const RightSide = styled(FlexBox)`
  width: 50%;
  transform: translateY(3.75rem);

  @media (max-width: 1023px) {
    width: 100%;
    justify-content: space-between;
    margin-top: 3rem;
    transform: none;
  }
`

interface ContainerProps {
  variant: Variant
}

export type Variant = 'default' | 'buttonColored'

import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  icon?: string
  label?: string
}

export const Service = memo(function Service({ icon, label }: Props) {
  if (!icon || !label) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      transform: 'scale(1)',
    },
    hidden: {
      opacity: 0,
      transform: 'scale(0.5)',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {icon ? <Icon alt={label} src={icon} width="60" height="60" /> : null}
      {label ? <Label>{label}</Label> : null}
    </Container>
  )
})

const Container = styled(motion.div)`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: calc(33.333% - 1.5rem);
  min-height: 13.333vw;
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  border: 0.625rem solid ${({ theme }) => theme.colors.variants.neutralLight4};
  margin: 1.5rem 1.5rem 0 0;
  padding: 0 1.875rem;
  text-align: center;

  @media (max-width: 1023px) {
    width: calc(50% - 0.375rem);
    min-height: auto;
    margin: 0.75rem 0 0 0;
    padding: 1.25rem 0;
  }
`

const Icon = styled.img`
  display: block;
  width: auto;
  height: 3.75rem;
  margin: 0 auto 1.25rem;

  @media (max-width: 1339px) {
    height: 3.4375rem;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralLight2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1.25rem;
  text-transform: uppercase;
`
